@media screen and (max-width: 400px) {
  .carousel {
    height: auto;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
  }

  .carousel-text {
    position: absolute;
    left: 15%;
    top: 30%;
    z-index: 1;
    color: white;
    font-size: xx-small;
  }

  .menu-btn {
    margin-top: 5%;
    float: right;
  }
  .ant-menu {
    display: none;
  }
  .ant-layout .ant-layout-header {
    background: none;
    position: absolute;
    top: 2%;
    width: 100vw;
    z-index: 1;
    height: auto;
  }
  .background-image {
    height: auto;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
  }

  .main-image {
    text-align: center;
    opacity: 0.5;
  }

  .image-text {
    position: absolute;
    left: 15%;
    top: 30%;
    z-index: 1;
    color: white;
    font-size: smaller;
  }
  .image-subtext {
    color: white;
    font-size: larger;
  }
}

@media screen and (max-width: 600px) {
  .carousel {
    height: auto;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
  }

  .carousel-text {
    position: absolute;
    left: 20%;
    top: 30%;
    z-index: 1;
    color: white;
    font-size: xx-small;
  }
  .menu-btn {
    margin-top: 5%;
    float: right;
  }
  .ant-menu {
    display: none;
  }
  .ant-layout .ant-layout-header {
    background: none;
    position: absolute;
    top: 2%;
    width: 100vw;
    z-index: 1;
    height: auto;
  }
  .background-image {
    height: auto;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
    width: 100vw;
    overflow: hidden;
  }

  .image-text {
    position: absolute;
    left: 30%;
    top: 30%;
    z-index: 1;
    color: white;
    font-size: xx-small;
  }
  .image-subtext {
    color: white;
    font-size: larger;
  }
}

@media screen and (min-width: 600px) {
  .carousel {
    height: auto;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
  }

  .carousel-text {
    position: absolute;
    left: 30%;
    margin-left: -50px;
    top: 45%;
    margin-top: -40px;
    z-index: 1;
    color: white;
    font-size: medium;
  }
  .menu-btn {
    display: none;
  }
  .ant-menu {
    border-bottom: 0px;
    float: right;
    font-size: large;
  }
  .ant-layout .ant-layout-header {
    background: none;
    position: absolute;
    top: 5%;
    width: 100vw;
    z-index: 1;
    height: auto;
  }
  .background-image {
    height: auto;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
    width: 100vw;
    overflow: hidden;
  }

  .image-text {
    position: absolute;
    left: 40%;
    margin-left: -50px;
    top: 45%;
    margin-top: -40px;
    z-index: 1;
    color: white;
    font-size: medium;
  }
  .image-subtext {
    color: white;
    font-size: larger;
  }
}

@media only screen and (min-width: 1199px) {
  .carousel {
    height: auto;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 0;
  }

  .carousel-text {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 40%;
    margin-top: -40px;
    z-index: 1;
    color: white;
    font-size: large;
  }
  .menu-btn {
    display: none;
  }
  .ant-menu {
    border-bottom: 0px;
    float: right;
    font-size: large;
  }
  .ant-layout .ant-layout-header {
    background: none;
    position: absolute;
    top: 5%;
    width: 100vw;
    z-index: 1;
    height: auto;
  }
  .background-image {
    height: auto;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
    width: 100vw;
    overflow: hidden;
  }

  .image-text {
    position: absolute;
    left: 30%;
    margin-left: -50px;
    top: 40%;
    margin-top: -40px;
    z-index: 1;
    color: white;
    font-size: large;
  }
  .image-subtext {
    color: white;
    font-size: larger;
  }
}

@media only screen and (min-width: 1200px) {
  .carousel {
    height: 90vh;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
  }

  .carousel-text {
    position: absolute;
    left: 30%;
    margin-left: -50px;
    top: 40%;
    margin-top: -40px;
    z-index: 1;
    color: white;
    font-size: large;
  }
  .menu-btn {
    display: none;
  }
  .ant-menu {
    border-bottom: 0px;
    float: right;
    font-size: large;
  }
  .ant-layout .ant-layout-header {
    background: none;
    position: absolute;
    top: 5%;
    width: 100vw;
    z-index: 1;
    height: auto;
  }
  .background-image {
    height: 90vh;
    text-align: center;
    background: black;
    position: relative;
    z-index: 0;
    width: 100vw;
    overflow: hidden;
  }

  .image-text {
    position: absolute;
    left: 30%;
    margin-left: -50px;
    top: 40%;
    margin-top: -40px;
    z-index: 1;
    color: white;
    font-size: large;
  }
  .image-subtext {
    color: white;
    font-size: larger;
  }
}

body {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  line-height: 1.7;
  font-size: large;
  color: gray;
}

.ant-menu-light {
  background: none;
  color: rgba(255, 255, 255, 1);
}

/* .brand-logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
} */
.carousel-image {
  text-align: center;
  opacity: 0.5;
}

.main-image {
  text-align: center;
  opacity: 0.5;
}

/* about us part */

.new-section {
  padding: 5% 0;
}
.new-section-alt {
  padding: 5% 0;
  background-color: #fafafa;
}

.container {
  width: 70%;
  padding: 0 15%;
  margin-left: auto;
  margin-right: auto;
}
.about-text .ant-col {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  line-height: 1.7;
  font-size: large;
  color: gray;
}
.about-preview {
  background-color: #1c76b2;
  color: white;
  width: 80%;
  margin: auto;
  padding: 8%;
}

.bold {
  font-size: 120px;
  font-weight: 700;
  line-height: 120px;
  text-align: center;
  display: block;
}

.light {
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;
  display: block;
}

.thin {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
  display: block;
}
.about-note {
  text-align: justify;
}
.service-font {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  line-height: 1.7;
  text-align: center;
  background-color: #fafafa;
  display: block;
  padding: 2%;
}

.service-font .bold {
  font-size: 60px;
  line-height: normal;
  color: black;
  text-transform: uppercase;
}

.service-font .thin {
  font-size: 30px;
  text-transform: uppercase;
}

.service-text {
  font-size: large;
  letter-spacing: 1px;
}

.service-innercard {
  max-height: max-content;
  margin: 5%;
  text-align: justify;
   
}

.service-avatar{
  overflow: auto;
  white-space: inherit;
  width: 50px;
  height: 50px;
  border-radius: 0%;
}

.standard-font{
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
}

 .service-innercard .bold{
  font-size: 25px;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 2px;
}

.service-detail{
  font-size: large;
}
 
